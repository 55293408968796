import { observable, action } from 'mobx';

/**
	This is the global state container for FMG-Dashboard
*/
class S_App {
    constructor() {
        this.is_logged_in = localStorage.getItem('token') || undefined;
    }

    @observable is_logged_in = false;
    /**
     * User is logged in or not
     * @param {bool} data
     */
    @action loginUser() {
        this.is_logged_in = true;
    }

    @action logoutUser() {
        localStorage.removeItem('token');
        this.is_logged_in = false;
    }
}

export default S_App;
